import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import WorkNav from 'components/work-nav'
import ArrowLink from 'components/link-with-arrow'
import Layout from 'components/layout'
import Clients3 from 'components/clients3'
import TobinQuote from 'components/tobinQuote'
import CaseStudyBlog from 'components/casestudyBlog'
import Customers from 'components/customers'
import WorkHero from 'components/workHero'

// Page Components
import IOTApps from './components/iot-apps'

function DeviceManufacturers({ data: { page, caseStudies } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <WorkNav />
      <WorkHero page={page} />
      <hr />
      <Clients3 />
      <TobinQuote />
      <IOTApps />
      <hr />
      <CaseStudyBlog caseStudies={caseStudies} />
      <Customers />
      <Section>
        <Container className="has-text-centered">
          <ArrowLink to="/work/services" className="is-info">
            Next: Services &amp; Packages
          </ArrowLink>
        </Container>
      </Section>
    </Layout>
  )
}

DeviceManufacturers.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DeviceManufacturers
